// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#HomePage-module-main {
  padding: 0 15px;
  position: relative;
  top: calc(0px - var(--height-header));
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

#HomePage-module-noProjectsMessage {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
  text-align: center;
  gap: 10px;
  position: fixed;
  width: 100%;
  left: 0;
  padding: 0 15px;
  box-sizing: border-box;
  z-index: -1;
}

#HomePage-module-options > button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 30px;
  background-color: var(--background);
  color: var(--text);
  border: 1px solid var(--border);
  width: 30px;
  cursor: initial;
  padding: 0;
}

#HomePage-module-projectListContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 15px;
}

#HomePage-module-projectList {
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style-type: none;
  margin: 0 auto;
  width: 100%;
  max-width: 1080px;
}`, "",{"version":3,"sources":["webpack://./src/components/HomePage/HomePage.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,qCAAqC;EACrC,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,eAAe;EACf,WAAW;EACX,OAAO;EACP,eAAe;EACf,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,mCAAmC;EACnC,kBAAkB;EAClB,+BAA+B;EAC/B,WAAW;EACX,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,qBAAqB;EACrB,cAAc;EACd,WAAW;EACX,iBAAiB;AACnB","sourcesContent":["#main {\n  padding: 0 15px;\n  position: relative;\n  top: calc(0px - var(--height-header));\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  z-index: 0;\n}\n\n#noProjectsMessage {\n  height: 100%;\n  flex-direction: column;\n  justify-content: center;\n  display: flex;\n  text-align: center;\n  gap: 10px;\n  position: fixed;\n  width: 100%;\n  left: 0;\n  padding: 0 15px;\n  box-sizing: border-box;\n  z-index: -1;\n}\n\n#options > button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 100%;\n  height: 30px;\n  background-color: var(--background);\n  color: var(--text);\n  border: 1px solid var(--border);\n  width: 30px;\n  cursor: initial;\n  padding: 0;\n}\n\n#projectListContainer {\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n  margin-top: 15px;\n}\n\n#projectList {\n  display: flex;\n  flex-direction: column;\n  padding: 0;\n  list-style-type: none;\n  margin: 0 auto;\n  width: 100%;\n  max-width: 1080px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `HomePage-module-main`,
	"noProjectsMessage": `HomePage-module-noProjectsMessage`,
	"options": `HomePage-module-options`,
	"projectListContainer": `HomePage-module-projectListContainer`,
	"projectList": `HomePage-module-projectList`
};
export default ___CSS_LOADER_EXPORT___;
