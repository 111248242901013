// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectListButton-module-project {
  width: 100%;
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  border: 1px solid transparent;
  box-sizing: border-box;
  padding: 0 15px;
}

.ProjectListButton-module-project:hover {
  background-color: rgb(230, 230, 230);
  box-shadow: none;
  border-radius: 5px;
  cursor: initial;
}

.ProjectListButton-module-project:hover:active {
  background-color: rgb(220, 220, 220);
}

.ProjectListButton-module-project.ProjectListButton-module-selected {
  background-color: rgb(225, 225, 225);
  border-color: var(--border);
}

.ProjectListButton-module-project > span {
  display: flex;
  align-items: center;
  gap: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/ProjectListButton/ProjectListButton.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,6BAA6B;EAC7B,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;EAChB,6BAA6B;EAC7B,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,oCAAoC;EACpC,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;EACpC,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".project {\n  width: 100%;\n  background-color: transparent;\n  border-radius: 5px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  min-height: 40px;\n  border: 1px solid transparent;\n  box-sizing: border-box;\n  padding: 0 15px;\n}\n\n.project:hover {\n  background-color: rgb(230, 230, 230);\n  box-shadow: none;\n  border-radius: 5px;\n  cursor: initial;\n}\n\n.project:hover:active {\n  background-color: rgb(220, 220, 220);\n}\n\n.project.selected {\n  background-color: rgb(225, 225, 225);\n  border-color: var(--border);\n}\n\n.project > span {\n  display: flex;\n  align-items: center;\n  gap: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"project": `ProjectListButton-module-project`,
	"selected": `ProjectListButton-module-selected`
};
export default ___CSS_LOADER_EXPORT___;
