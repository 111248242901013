// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateInput-module-toggleButton {
  background-color: white;
  border: 1px solid var(--border);
  box-shadow: var(--box-shadow-default);
  color: var(--text);
}`, "",{"version":3,"sources":["webpack://./src/components/DateInput/DateInput.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,+BAA+B;EAC/B,qCAAqC;EACrC,kBAAkB;AACpB","sourcesContent":[".toggleButton {\n  background-color: white;\n  border: 1px solid var(--border);\n  box-shadow: var(--box-shadow-default);\n  color: var(--text);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleButton": `DateInput-module-toggleButton`
};
export default ___CSS_LOADER_EXPORT___;
