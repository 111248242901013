// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#TaskPopupAttachmentSection-module-attachments {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#TaskPopupAttachmentSection-module-attachments a {
  text-decoration: none;
  word-break: break-word;
}

#TaskPopupAttachmentSection-module-attachments li > section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#TaskPopupAttachmentSection-module-attachments li > section > section {
  display: flex;
  flex-direction: column;
}

#TaskPopupAttachmentSection-module-attachments li > section > button {
  background-color: transparent;
  box-shadow: none;
  color: var(--text);
  padding: 0;
  height: 24px;
  width: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/TaskPopupAttachmentSection/TaskPopupAttachmentSection.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,WAAW;AACb","sourcesContent":["#attachments {\n  list-style-type: none;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n#attachments a {\n  text-decoration: none;\n  word-break: break-word;\n}\n\n#attachments li > section {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n#attachments li > section > section {\n  display: flex;\n  flex-direction: column;\n}\n\n#attachments li > section > button {\n  background-color: transparent;\n  box-shadow: none;\n  color: var(--text);\n  padding: 0;\n  height: 24px;\n  width: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attachments": `TaskPopupAttachmentSection-module-attachments`
};
export default ___CSS_LOADER_EXPORT___;
