// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#TaskPopupSubTaskSection-module-tasks {
  padding: 0;
  margin: 16px 0 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

#TaskPopupSubTaskSection-module-tasks li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#TaskPopupSubTaskSection-module-tasks a {
  color: var(--text);
  text-decoration: none;
}

#TaskPopupSubTaskSection-module-tasks li > button {
  background-color: transparent;
  height: 100%;
  color: var(--text);
  height: 30.1px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}

#TaskPopupSubTaskSection-module-tasks li > span {
  display: flex;
  gap: 10px;
  align-items: center;
}

#TaskPopupSubTaskSection-module-tasks li > span > span {
  padding: 5px;
  border-radius: 5px;
  font-weight: 400;
  flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/TaskPopupSubTaskSection/TaskPopupSubTaskSection.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":["#tasks {\n  padding: 0;\n  margin: 16px 0 0;\n  list-style: none;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n#tasks li {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n#tasks a {\n  color: var(--text);\n  text-decoration: none;\n}\n\n#tasks li > button {\n  background-color: transparent;\n  height: 100%;\n  color: var(--text);\n  height: 30.1px;\n  aspect-ratio: 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  box-shadow: none;\n}\n\n#tasks li > span {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n}\n\n#tasks li > span > span {\n  padding: 5px;\n  border-radius: 5px;\n  font-weight: 400;\n  flex-shrink: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tasks": `TaskPopupSubTaskSection-module-tasks`
};
export default ___CSS_LOADER_EXPORT___;
