// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

#TaskPopup-module-description {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#TaskPopup-module-description [placeholder]:empty::before {
  content: attr(placeholder);
  color: #b8b8b8; 
  cursor: text;
  pointer-events: none;
}

#TaskPopup-module-description [placeholder]:empty:focus::before {
  content: "";
}

#TaskPopup-module-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
}

#TaskPopup-module-details > section {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

#TaskPopup-module-details > section > label {
  display: flex;
  gap: 5px;
  align-items: center;
  min-height: 24px;
}

.TaskPopup-module-expired {
  color: var(--text-red);
}

#TaskPopup-module-placeholder {
  position: fixed;
}

#TaskPopup-module-popupContent > nav {
  border-bottom: 1px solid var(--border);
  margin: 0 -15px;
  padding: 0 15px 15px;
}

#TaskPopup-module-popupContent > nav > button {
  background-color: transparent;
  font-weight: 300;
  padding: 5px 10px;
  color: var(--text);
}

#TaskPopup-module-popupContent > nav > button.TaskPopup-module-selected {
  font-weight: 400;
  color: var(--link);
  background-color: var(--link-selected);
}`, "",{"version":3,"sources":["webpack://./src/components/TaskPopup/TaskPopup.module.css"],"names":[],"mappings":";;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,0BAA0B;EAC1B,cAAc;EACd,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sCAAsC;EACtC,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,sCAAsC;AACxC","sourcesContent":["\n\n#description {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n#description [placeholder]:empty::before {\n  content: attr(placeholder);\n  color: #b8b8b8; \n  cursor: text;\n  pointer-events: none;\n}\n\n#description [placeholder]:empty:focus::before {\n  content: \"\";\n}\n\n#details {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  overflow-y: auto;\n}\n\n#details > section {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n\n#details > section > label {\n  display: flex;\n  gap: 5px;\n  align-items: center;\n  min-height: 24px;\n}\n\n.expired {\n  color: var(--text-red);\n}\n\n#placeholder {\n  position: fixed;\n}\n\n#popupContent > nav {\n  border-bottom: 1px solid var(--border);\n  margin: 0 -15px;\n  padding: 0 15px 15px;\n}\n\n#popupContent > nav > button {\n  background-color: transparent;\n  font-weight: 300;\n  padding: 5px 10px;\n  color: var(--text);\n}\n\n#popupContent > nav > button.selected {\n  font-weight: 400;\n  color: var(--link);\n  background-color: var(--link-selected);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `TaskPopup-module-description`,
	"details": `TaskPopup-module-details`,
	"expired": `TaskPopup-module-expired`,
	"placeholder": `TaskPopup-module-placeholder`,
	"popupContent": `TaskPopup-module-popupContent`,
	"selected": `TaskPopup-module-selected`
};
export default ___CSS_LOADER_EXPORT___;
