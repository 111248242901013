// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingsPageOption-module-labelBaseInfo {
  display: flex;
  justify-content: space-between;
}

.SettingsPageOption-module-labelBaseInfo > button {
  padding: 0;
  background-color: transparent;
  color: var(--color-text);
  border-radius: 0;
  height: 24px;
}

.SettingsPageOption-module-labelBaseInfo > span {
  display: flex;
  gap: 15px;
  align-items: center;
}

.SettingsPageOption-module-labelColor {
  background-color: gray;
  border-radius: 100%;
  height: 15px;
  width: 15px;
}

.SettingsPageOption-module-labelDescription {
  margin: 0 -15px -15px;
  padding: 15px;
  border-top: 1px solid var(--border);
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/components/SettingsPageOption/SettingsPageOption.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,UAAU;EACV,6BAA6B;EAC7B,wBAAwB;EACxB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,mCAAmC;EACnC,sBAAsB;AACxB","sourcesContent":[".labelBaseInfo {\n  display: flex;\n  justify-content: space-between;\n}\n\n.labelBaseInfo > button {\n  padding: 0;\n  background-color: transparent;\n  color: var(--color-text);\n  border-radius: 0;\n  height: 24px;\n}\n\n.labelBaseInfo > span {\n  display: flex;\n  gap: 15px;\n  align-items: center;\n}\n\n.labelColor {\n  background-color: gray;\n  border-radius: 100%;\n  height: 15px;\n  width: 15px;\n}\n\n.labelDescription {\n  margin: 0 -15px -15px;\n  padding: 15px;\n  border-top: 1px solid var(--border);\n  box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelBaseInfo": `SettingsPageOption-module-labelBaseInfo`,
	"labelColor": `SettingsPageOption-module-labelColor`,
	"labelDescription": `SettingsPageOption-module-labelDescription`
};
export default ___CSS_LOADER_EXPORT___;
