// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#HeaderOptions-module-options {
  margin: var(--height-header) -15px 0;
  border-bottom: 1px solid var(--border);
  padding: 0 15px 15px;
  gap: 15px;
  display: flex;
  align-items: center;
  height: var(--height-header);
  z-index: 1;
  justify-content: space-between;
}

#HeaderOptions-module-options > span {
  display: flex;
  gap: 15px;
}

#HeaderOptions-module-options button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 30px;
  background-color: var(--background);
  color: var(--text);
  width: 30px;
  cursor: initial;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/HeaderOptions/HeaderOptions.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,sCAAsC;EACtC,oBAAoB;EACpB,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,4BAA4B;EAC5B,UAAU;EACV,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,mCAAmC;EACnC,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,UAAU;AACZ","sourcesContent":["#options {\n  margin: var(--height-header) -15px 0;\n  border-bottom: 1px solid var(--border);\n  padding: 0 15px 15px;\n  gap: 15px;\n  display: flex;\n  align-items: center;\n  height: var(--height-header);\n  z-index: 1;\n  justify-content: space-between;\n}\n\n#options > span {\n  display: flex;\n  gap: 15px;\n}\n\n#options button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 100%;\n  height: 30px;\n  background-color: var(--background);\n  color: var(--text);\n  width: 30px;\n  cursor: initial;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"options": `HeaderOptions-module-options`
};
export default ___CSS_LOADER_EXPORT___;
