// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LabelButton-module-label {
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: rgb(253, 253, 253);
  border-radius: 5px;
  padding: 0 5px;
  box-shadow: var(--box-shadow-default);
}

.LabelButton-module-colorBubble {
  background-color: var(--button);
  border-radius: 100%;
  width: 15px;
  height: 15px;
  padding: 0;
  display: flex;
  flex-shrink: 0;
}

.LabelButton-module-colorBubble:hover > span {
  display: inline-block;
}

.LabelButton-module-colorBubble > span {
  font-size: 15px;
  display: none;
}

.LabelButton-module-colorBubble + span {
  overflow-wrap: anywhere;
}

.LabelButton-module-label > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/components/LabelButton/LabelButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,oCAAoC;EACpC,kBAAkB;EAClB,cAAc;EACd,qCAAqC;AACvC;;AAEA;EACE,+BAA+B;EAC/B,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,UAAU;EACV,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,aAAa;AACf;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".label {\n  display: flex;\n  gap: 5px;\n  align-items: center;\n  background-color: rgb(253, 253, 253);\n  border-radius: 5px;\n  padding: 0 5px;\n  box-shadow: var(--box-shadow-default);\n}\n\n.colorBubble {\n  background-color: var(--button);\n  border-radius: 100%;\n  width: 15px;\n  height: 15px;\n  padding: 0;\n  display: flex;\n  flex-shrink: 0;\n}\n\n.colorBubble:hover > span {\n  display: inline-block;\n}\n\n.colorBubble > span {\n  font-size: 15px;\n  display: none;\n}\n\n.colorBubble + span {\n  overflow-wrap: anywhere;\n}\n\n.label > span {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  max-width: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `LabelButton-module-label`,
	"colorBubble": `LabelButton-module-colorBubble`
};
export default ___CSS_LOADER_EXPORT___;
