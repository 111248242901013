// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#HeaderProjectSwticher-module-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: var(--text);
  gap: 5px;
  padding: 0 7px;
  max-width: 246px;
  height: 40px;
  border: 1px solid var(--border);
  height: 100%;
}

#HeaderProjectSwticher-module-name {
  display: none;
}

@media screen and (min-width: 400px) {
  
  #HeaderProjectSwticher-module-button {
    padding: 0 10px;
  }

  #HeaderProjectSwticher-module-name {
    display: initial;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

}`, "",{"version":3,"sources":["webpack://./src/components/HeaderProjectSwitcher/HeaderProjectSwticher.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,kBAAkB;EAClB,QAAQ;EACR,cAAc;EACd,gBAAgB;EAChB,YAAY;EACZ,+BAA+B;EAC/B,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;EACzB;;AAEF","sourcesContent":["#button {\n  display: flex;\n  align-items: center;\n  background-color: transparent;\n  color: var(--text);\n  gap: 5px;\n  padding: 0 7px;\n  max-width: 246px;\n  height: 40px;\n  border: 1px solid var(--border);\n  height: 100%;\n}\n\n#name {\n  display: none;\n}\n\n@media screen and (min-width: 400px) {\n  \n  #button {\n    padding: 0 10px;\n  }\n\n  #name {\n    display: initial;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `HeaderProjectSwticher-module-button`,
	"name": `HeaderProjectSwticher-module-name`
};
export default ___CSS_LOADER_EXPORT___;
