// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BacklogTask-module-labels {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 10px;
}

.BacklogTask-module-status {
  border-radius: 100%;
  height: 20px;
  width: 20px;
  padding: 0;
  flex-shrink: 0;
}

.BacklogTask-module-statusContainer {
  display: flex;
  pointer-events: all;
}

.BacklogTask-module-task {
  width: 100%;
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  border: 1px solid transparent;
  box-sizing: border-box;
  padding: 0 15px;
}

.BacklogTask-module-task:hover {
  background-color: rgb(230, 230, 230);
  box-shadow: none;
  border-radius: 5px;
  cursor: initial;
}

.BacklogTask-module-task:hover:active {
  background-color: rgb(220, 220, 220);
}

.BacklogTask-module-task.BacklogTask-module-selected {
  background-color: rgb(225, 225, 225);
  border-color: var(--border);
}

.BacklogTask-module-task > span {
  display: flex;
  align-items: center;
  gap: 15px;
}

.BacklogTask-module-taskOptions {
  border-radius: 100%;
  height: 24px;
  padding: 0;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: var(--text);
  z-index: 1;
  pointer-events: all;
}`, "",{"version":3,"sources":["webpack://./src/components/BacklogTask/BacklogTask.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,UAAU;EACV,SAAS;EACT,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,6BAA6B;EAC7B,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;EAChB,6BAA6B;EAC7B,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,oCAAoC;EACpC,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;EACpC,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,UAAU;EACV,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,6BAA6B;EAC7B,kBAAkB;EAClB,UAAU;EACV,mBAAmB;AACrB","sourcesContent":[".labels {\n  display: flex;\n  padding: 0;\n  margin: 0;\n  list-style: none;\n  gap: 10px;\n}\n\n.status {\n  border-radius: 100%;\n  height: 20px;\n  width: 20px;\n  padding: 0;\n  flex-shrink: 0;\n}\n\n.statusContainer {\n  display: flex;\n  pointer-events: all;\n}\n\n.task {\n  width: 100%;\n  background-color: transparent;\n  border-radius: 5px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  min-height: 40px;\n  border: 1px solid transparent;\n  box-sizing: border-box;\n  padding: 0 15px;\n}\n\n.task:hover {\n  background-color: rgb(230, 230, 230);\n  box-shadow: none;\n  border-radius: 5px;\n  cursor: initial;\n}\n\n.task:hover:active {\n  background-color: rgb(220, 220, 220);\n}\n\n.task.selected {\n  background-color: rgb(225, 225, 225);\n  border-color: var(--border);\n}\n\n.task > span {\n  display: flex;\n  align-items: center;\n  gap: 15px;\n}\n\n.taskOptions {\n  border-radius: 100%;\n  height: 24px;\n  padding: 0;\n  width: 24px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: transparent;\n  color: var(--text);\n  z-index: 1;\n  pointer-events: all;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labels": `BacklogTask-module-labels`,
	"status": `BacklogTask-module-status`,
	"statusContainer": `BacklogTask-module-statusContainer`,
	"task": `BacklogTask-module-task`,
	"selected": `BacklogTask-module-selected`,
	"taskOptions": `BacklogTask-module-taskOptions`
};
export default ___CSS_LOADER_EXPORT___;
