// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Backlog-module-main {
  padding: 0 15px;
  position: relative;
  top: calc(0px - var(--height-header));
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

#Backlog-module-noTasksMessage {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
  text-align: center;
  gap: 10px;
  position: fixed;
  width: 100%;
  left: 0;
  padding: 0 15px;
  box-sizing: border-box;
}

#Backlog-module-taskList {
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style-type: none;
  margin: 0 auto;
  width: 100%;
  max-width: 1080px;
}

#Backlog-module-taskListContainer {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Backlog/Backlog.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,qCAAqC;EACrC,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,eAAe;EACf,WAAW;EACX,OAAO;EACP,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,qBAAqB;EACrB,cAAc;EACd,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":["#main {\n  padding: 0 15px;\n  position: relative;\n  top: calc(0px - var(--height-header));\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  z-index: 0;\n}\n\n#noTasksMessage {\n  height: 100%;\n  flex-direction: column;\n  justify-content: center;\n  display: flex;\n  text-align: center;\n  gap: 10px;\n  position: fixed;\n  width: 100%;\n  left: 0;\n  padding: 0 15px;\n  box-sizing: border-box;\n}\n\n#taskList {\n  display: flex;\n  flex-direction: column;\n  padding: 0;\n  list-style-type: none;\n  margin: 0 auto;\n  width: 100%;\n  max-width: 1080px;\n}\n\n#taskListContainer {\n  display: flex;\n  flex-direction: column;\n  margin-top: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Backlog-module-main`,
	"noTasksMessage": `Backlog-module-noTasksMessage`,
	"taskList": `Backlog-module-taskList`,
	"taskListContainer": `Backlog-module-taskListContainer`
};
export default ___CSS_LOADER_EXPORT___;
