// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#SettingsPage-module-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#SettingsPage-module-list, #SettingsPage-module-main, .SettingsPage-module-labelDescription, #SettingsPage-module-listContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#SettingsPage-module-list > li {
  border-radius: 5px;
  padding: 15px;
  box-shadow: var(--box-shadow-default);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 15px;
}

#SettingsPage-module-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.SettingsPage-module-labelActions {
  display: flex;
  gap: 15px;
}

#SettingsPage-module-main {
  padding: 15px;
  box-sizing: border-box;
  overflow-y: auto;
  height: calc(100% - var(--height-header));
  align-items: center;
}

#SettingsPage-module-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 1080px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/SettingsPage/SettingsPage.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,qCAAqC;EACrC,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,yCAAyC;EACzC,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,iBAAiB;EACjB,WAAW;AACb","sourcesContent":["#list {\n  list-style-type: none;\n  padding: 0;\n  margin: 0;\n}\n\n#list, #main, .labelDescription, #listContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n#list > li {\n  border-radius: 5px;\n  padding: 15px;\n  box-shadow: var(--box-shadow-default);\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n  gap: 15px;\n}\n\n#info {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n\n.labelActions {\n  display: flex;\n  gap: 15px;\n}\n\n#main {\n  padding: 15px;\n  box-sizing: border-box;\n  overflow-y: auto;\n  height: calc(100% - var(--height-header));\n  align-items: center;\n}\n\n#content {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  max-width: 1080px;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `SettingsPage-module-list`,
	"main": `SettingsPage-module-main`,
	"labelDescription": `SettingsPage-module-labelDescription`,
	"listContainer": `SettingsPage-module-listContainer`,
	"info": `SettingsPage-module-info`,
	"labelActions": `SettingsPage-module-labelActions`,
	"content": `SettingsPage-module-content`
};
export default ___CSS_LOADER_EXPORT___;
